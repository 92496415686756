/* ==============
  Calendar
===================*/

.lnb-calendars-item {
    display: inline-block;
    margin-right: 7px;
}

input[type="checkbox"].tui-full-calendar-checkbox-round + span {
    margin-right: 4px /*rtl: 0*/;
    margin-left: 0 /*rtl: 4px*/;
}

.tui-full-calendar-layout,
.tui-full-calendar-timegrid-timezone {
    background-color: $card-bg !important;
}

.tui-full-calendar-dayname-container,
.tui-full-calendar-left,
.tui-full-calendar-splitter,
.tui-full-calendar-time-date,
.tui-full-calendar-weekday-grid-line,
.tui-full-calendar-timegrid-timezone,
.tui-full-calendar-timegrid-gridline {
    border-color: $gray-300 !important;
}

.tui-full-calendar-weekday-exceed-in-week {
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 28px;
    border-radius: 4px;
    background-color: $card-bg;
    color: $body-color;
    border-color: $border-color;
}

.tui-full-calendar-timegrid-hour {
    color: $body-color !important;
}

.tui-full-calendar-weekday-schedule-title {
    color: $gray-900 !important;
    .tui-full-calendar-time-schedule {
        font-weight: $font-weight-semibold;
    }
}
.tui-full-calendar-popup-container {
    background-color: $card-bg !important;
    border-color: $border-color !important;
}

.tui-full-calendar-arrow-bottom .tui-full-calendar-popup-arrow-fill {
    border-top-color: $border-color !important;
}
.tui-full-calendar-arrow-top .tui-full-calendar-popup-arrow-fill {
    border-bottom-color: $border-color !important;
}

.tui-full-calendar-arrow-bottom .tui-full-calendar-popup-arrow-borde {
    border-bottom-color: $border-color !important;
}

.tui-full-calendar-button {
    color: $input-color;
    background-color: $input-bg !important;
    border-color: $input-border-color;
}
.tui-full-calendar-popup-section-item {
    border-color: $input-border-color;
}

.tui-full-calendar-dropdown-menu-item,
.tui-full-calendar-dropdown-menu {
    background-color: $input-bg !important;
}

.tui-full-calendar-arrow-bottom .tui-full-calendar-popup-arrow-border {
    border-top-color: $border-color;
}
.tui-full-calendar-content {
    background-color: $input-bg !important;
    color: $input-color !important;
}

.tui-full-calendar-confirm {
    background-color: $danger !important;
    color: $white !important;
    &:hover {
        background-color: $red-600 !important;
        color: $white !important;
    }
}

.tui-full-calendar-month-dayname-item{
    span{
        color: $gray-900 !important;
    }
}

tui-full-calendar-weekday-grid-date.tui-full-calendar-weekday-grid-date-decorator{
    color: $white !important;
}

.tui-full-calendar-weekday-grid-line {
    &.tui-full-calendar-near-month-day {
        &.tui-full-calendar-extra-date {
            .tui-full-calendar-weekday-grid-header {
                .tui-full-calendar-weekday-grid-date {
                    color: $gray-400 !important;
                }
            }
        }
    }
}


.tui-full-calendar-weekday-grid-line {
    &.tui-full-calendar-near-month-day {
        .tui-full-calendar-weekday-grid-header {
            .tui-full-calendar-weekday-grid-date {
                color: $gray-800 !important;
            }
        }
    }
}
